import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import showPasswordIcon from '../assets/show-password.png';
import hidePasswordIcon from '../assets/hide.png';
import config from '../config';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await fetch(`${config.apiBaseUrl}/api/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('accessToken', data.token); // Corrected typo here
        navigate('/dashboard');
      } else {
        setError(data.message || 'An error occurred. Please try again.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen relative" style={{ background: "linear-gradient(rgba(0, 0, 128, 1), rgba(0, 0, 61, 1))" }}>
      <div className="relative text-center ">
        <h2 className="text-2xl font-bold text-white mb-6">Viszapp</h2>
        <h3 className="text-3xl font-semibold text-white mb-11">Admin Login</h3>
      </div>
      <div className="relative z-10 bg-white p-5 rounded-xl shadow-lg max-w-md w-full">
        <div className='m-7'>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-black mb-2" htmlFor="email">Email</label>
              <input
                className="w-full p-2 border border-gray-300 rounded-md"
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-black mb-2" htmlFor="password">Password</label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className="w-full p-3 border rounded-lg focus:outline-none focus:border-blue-500"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button className="text-blue-800 flex justify-end mx-2 m-3">Forgot?</button>
                <button
                  type="button"
                  className="absolute right-3 top-3"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <img src={showPasswordIcon} alt="Show Password" className="h-7 w-7" />
                  ) : (
                    <img src={hidePasswordIcon} alt="Hide Password" className="h-6 w-6" />
                  )}
                </button>
              </div>
            </div>

            {error && <div className="text-red-500 mb-4">{error}</div>}

            <button className="w-full bg-blue-700 text-white p-2 mt-3 rounded-md font-semibold" type="submit" disabled={loading}>
              {loading ? <span>Loading...</span> : <span>Login now</span>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
