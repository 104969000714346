import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import config from '../config';
import Sidebar from './Sidebar';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterOption, setFilterOption] = useState('');
  const [totalCustomers, setTotalCustomers] = useState(0);
  const customersPerPage = 10;

  const fetchCustomers = useCallback(async () => {
    try {
      const limit = customersPerPage;
      const offset = (currentPage - 1) * limit;
      
      const response = await axios.get(`${config.apiBaseUrl}/api/customer/customers`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          limit,
          offset,
          search: searchText,
          filter: filterOption,
        },
      });
      
      setCustomers(response.data.customers);
      setTotalCustomers(response.data.totalCount);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  }, [currentPage, searchText, filterOption]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1); // Reset to the first page on new search
    fetchCustomers();
  };

  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
  };

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= Math.ceil(totalCustomers / customersPerPage)) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="p-6 m-5 min-h-screen flex-1">
        <h1 className="text-3xl font-bold mb-6">All Customers</h1>
        <div className="flex flex-col mb-6">
          <div className="flex items-center mb-4">
            <label className="mr-4 text-lg">Search by:</label>
            <select
              value={filterOption}
              onChange={handleFilterOptionChange}
              className="mr-4 p-2 rounded text-black"
            >
              <option value="">Select Option</option>
              <option value="name">Name</option>
              <option value="email">Email</option>
            </select>
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              className="mr-4 p-2 rounded text-black"
              placeholder="Enter search text"
            />
            <button
              onClick={handleSearchSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5"
            >
              Search
            </button>
          </div>
        </div>
        <table className="min-w-full bg-white text-black">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Customer ID</th>
              <th className="py-2 px-4 border-b">Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Mode</th>
              <th className="py-2 px-4 border-b">Date Created</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.userid}>
                <td className="py-2 px-4 border-b text-center">{customer.userid}</td>
                <td className="py-2 px-4 border-b text-center">{customer.name}</td>
                <td className="py-2 px-4 border-b text-center">{customer.email}</td>
                <td className="py-2 px-4 border-b text-center">{customer.mode}</td>
                <td className="py-2 px-4 border-b text-center">
                  {new Date(customer.created).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <a href={`/customers/${customer.userid}`}>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-6">
          <div>
            Showing {customers.length} of {totalCustomers} customers
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
          >
            Previous
          </button>
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage * customersPerPage >= totalCustomers}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Customers;
