import React, { useState, useEffect } from 'react';

const Filter = ({ onFilter, fromDate, toDate }) => {
  const [localFromDate, setLocalFromDate] = useState(fromDate);
  const [localToDate, setLocalToDate] = useState(toDate);

  useEffect(() => {
    setLocalFromDate(fromDate);
    setLocalToDate(toDate);
  }, [fromDate, toDate]);

  const handleFilterSubmit = () => {
    onFilter(localFromDate, localToDate);
  };

  return (
    <div className="flex items-center space-x-4">
      <label>From:</label>
      <input
        type="date"
        value={localFromDate}
        onChange={(e) => setLocalFromDate(e.target.value)}
        className="p-2 rounded text-black"
      />
      <label>To:</label>
      <input
        type="date"
        value={localToDate}
        onChange={(e) => setLocalToDate(e.target.value)}
        className="p-2 rounded text-black"
      />
      <button
        onClick={handleFilterSubmit}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Filter
      </button>
    </div>
  );
};

export default Filter;
