import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import config from '../config';

const Dashboard = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    bookingsCount: 0,
    usersCount: 0,
    successfulPaymentsSum: 0
  });

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        
        const response = await fetch(`${config.apiBaseUrl}/api/admin/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        setProfileData({
          name: data.name,
          bookingsCount: data.bookingsCount,
          usersCount: data.usersCount,
          successfulPaymentsSum: data.successfulPaymentsSum
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []); // Run once on component mount

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="flex-1 p-6">
        <div className="flex justify-end mb-4">
          <div className="bg-gray-700 px-4 py-2 rounded">{profileData.name}</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-4 m-2 rounded-md flex h-[200px]  flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Booking</div>
            <div className="text-3xl font-bold text-gray-900 m-2">{profileData.bookingsCount}</div>
          </div>
          <div className="bg-white p-4 m-2 rounded-md flex h-[200px] flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Customers</div>
            <div className="text-3xl font-bold text-gray-900 m-2">{profileData.usersCount}</div>
          </div>
          <div className="bg-white p-4 m-2 rounded-md flex h-[200px] flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Total Revenue</div>
            <div className="text-3xl font-bold text-gray-900 m-2">{profileData.successfulPaymentsSum}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
