import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import config from '../config';
import Filter from '../shared/Filter';
import axios from 'axios';

const Bookings = () => {
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterOption, setFilterOption] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const lastWeekDate = new Date();
    lastWeekDate.setDate(currentDate.getDate() - 7);

    setFromDate(lastWeekDate.toISOString().split('T')[0]);
    setToDate(currentDate.toISOString().split('T')[0]);
    fetchBookings(1);
    
  }, [currentPage, searchText, filterOption, fromDate, toDate]);

   // Handle pagination
   useEffect(() => {
    if (currentPage > 1) {
      fetchBookings(currentPage);
    }
  }, [currentPage]);

  const fetchBookings = async (page) => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/api/book/bookings`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        },
        params: {
          page: page,
          limit: 10 // Assuming we want 10 results per page
        },
      });

      setBookings(response.data.bookings);
      setTotalBookings(response.data.totalCount);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  // const handlePageChange = (newPage) => {
  //   setCurrentPage(newPage);
  // };
  
  const handleFilter = (fromDate, toDate) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setCurrentPage(1);
    fetchBookings();
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    fetchBookings();
  };

  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
    setCurrentPage(1);
  };
  

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="p-6 m-5 min-h-screen flex-1">
        <h1 className="text-3xl font-bold mb-6">All Bookings</h1>
        <div className="flex flex-col mb-6">
          <div className="flex items-center mb-4">
            <label className="mr-4 text-lg">Choose:</label>
            <select
              value={filterOption}
              onChange={handleFilterOptionChange}
              className="mr-4 p-2 rounded text-black"
            >
              <option value="">Select Option</option>
              <option value="customerName">Customer Name</option>
              <option value="orderId">Order ID</option>
              <option value="visa">Country Visa</option>
            </select>
            <input
              type="text"
              value={searchText}
              onChange={handleSearch}
              className="mr-4 p-2 rounded text-black"
              placeholder="Enter search text"
            />
            <button
              onClick={handleSearchSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-5"
            >
              Search
            </button>
            <Filter onFilter={handleFilter} fromDate={fromDate} toDate={toDate} />
          </div>
        </div>
        <table className="min-w-full bg-white text-black">
          <thead>
            <tr>
            <th className="py-2 px-4 border-b">BookingID</th>
              <th className="py-2 px-4 border-b">OrderID</th>
              <th className="py-2 px-4 border-b">Customer Name</th>
              <th className="py-2 px-4 border-b">Visa Type</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Appointments</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.bookid}>
                 <td className="py-2 px-4 border-b text-center">{booking.bookid}</td>
                <td className="py-2 px-4 border-b text-center">{booking.bid}</td>
                <td className="py-2 px-4 border-b text-center">{booking.customer_name}</td>
                <td className="py-2 px-4 border-b text-center">{booking.visatype}</td>
                <td className="py-2 px-4 border-b text-center">{booking.status}</td>
                <td className="py-2 px-4 border-b text-center">{booking.appointment_count}</td>
                <td className="py-2 px-4 border-b text-center">{new Date(booking.createdon).toLocaleDateString()}</td>
                <td className="py-2 px-4 border-b text-center">
                <a href={`/bookings/${booking.bookid}`}>
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    View
                  </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-6">
          <div>
            Showing {bookings.length} of {totalBookings} bookings
          </div>
          <div>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Previous
            </button>
            <span className="mx-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;