import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const BookingDetails = () => {
  const { bookid } = useParams();
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/api/book/bookings/${bookid}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });
        setBookingDetails(response.data);
      } catch (error) {
        console.error('Error fetching booking details:', error);
      }
    };

    fetchBookingDetails();
  }, [bookid]);

  if (!bookingDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 m-5 min-h-screen bg-gray-800 text-white">
      <button 
        onClick={() => navigate('/bookings')} 
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-6"
      >
        Back
      </button>
      <h1 className="text-3xl font-bold mb-6">Booking Details</h1>
      <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Order ID</strong>
              {bookingDetails.bid}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Customer Name</strong>
              {bookingDetails.customer_name}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Customer Email</strong>
              {bookingDetails.email}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Customer Phone</strong>
              {bookingDetails.phone}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Visa Type</strong>
              {bookingDetails.visatype}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Plan</strong>
              {bookingDetails.plan}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Booking ID</strong>
              {bookingDetails.bookid}
            </p>
          </div>
          <div>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Drive</strong>
              {bookingDetails.drive}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Nation</strong>
              {bookingDetails.nation}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Occupation</strong>
              {bookingDetails.occ}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Additional Info</strong>
              {bookingDetails.anything}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Promo</strong>
              {bookingDetails.promo}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Status</strong>
              {bookingDetails.status}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Appointments</strong>
              {bookingDetails.appointment_count}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Created On</strong>
              {new Date(bookingDetails.createdon).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
